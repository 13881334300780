// axios
import axios from 'axios'
//axios.defaults.baseURL = 'http://51.38.57.172:9030/api/'
//axios.defaults.baseURL = 'http://89.147.102.129:9030/api/'
//axios.defaults.baseURL = 'https://jra.mitigarisk.com/api/'
axios.defaults.baseURL = 'https://dev.jra.mitigarisk.com/api/'
//axios.defaults.baseURL = 'http://145.239.11.24:9029/api/'
//axios.defaults.baseURL = 'https://test.jra.mitigarisk.com/api/'

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`

export default axios.create({})
